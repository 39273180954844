<template>
    <div>
        <div :class="[$route.name == 'verified-email' && 'd-none']">
          <v-header class="mb-5">
            <template slot="content">
              <carrier-header v-if="$route.name === 'carrier'" />
              <shipper-header v-if="$route.name === 'shipper'" />
              <personalized-header v-if="['terminos-legales', 'acerca', 'politica','privacy','politica-calidad'].includes($route.name)" :title="personalized_tittle"/>
              <blog-header v-if="['blogdetail'].includes($route.name)"/>
            </template>
          </v-header>
          <router-view/>
          <contact-form v-if="($route.name === 'shipper.thank-you' || $route.name !== 'carrier.thank-you') && $route.name !== 'shipper'"/>
          <v-footer />
        </div>
        <verified-email v-if="$route.name == 'verified-email'" />
    </div>
</template>
<script>
import Header from "./components/Header";
import CarrierHeader from "./components/CarrierHeader";
import ShipperHeader from "./components/ShipperHeader";
import PersonalizedHeader from "./components/PersonalizedHeader";
import BlogHeader from "./components/BlogHeader";
import Contact from "./components/ContactForm"
import Footer from "./components/Footer"
import VerifiedEmail from "./components/VerifiedEmail"
import {mapActions} from "vuex"

export default {
  name: "App",
  data() {
    return{
      title_header: ''
    }
  },
  mounted(){
    this.fetchPosts({per_page: 3})
  },
  methods: {
    ...mapActions(["fetchPosts"])
  },
  components: {
    'v-header': Header,
    'contact-form': Contact,
    'v-footer': Footer,
    CarrierHeader,
    ShipperHeader,
    PersonalizedHeader,
    BlogHeader,
    VerifiedEmail
  },
  computed:{
    personalized_tittle(){
      let title_header  = '';
      switch(this.$route.name){
        case 'terminos-legales':
          title_header = 'Términos legales';
          break;
        case 'acerca':
          title_header = 'Acerca de la compañía';
          break;
        case 'politica':
          title_header = 'Política de Privacidad';
          break;
        case 'privacy':
          title_header = 'Aviso de privacidad';
          break;
        case 'politica-calidad':
          title_header = 'Política de Calidad';
          break;
        default:
          title_header = this.$route.name;
          break;
      }

      return title_header;
    }
  }
}
</script>

<style lang="scss">
#app {
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.d-none {
    display: none;
}
</style>
