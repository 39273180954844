import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

const formattedPost = (item)=>{
  return {
    id: item.id,
    slug: item.slug,
    title: item.title.rendered,
    categories:  item['_embedded']['wp:term'][0],
    content: item.content.rendered.substr(0, 200).replace(/(<([^>]+)>)/gi, "") + '...',
    image: item['_embedded']['wp:featuredmedia'].length > 0 ?
        item['_embedded']['wp:featuredmedia'][0].media_details.sizes['hestia-blog'].source_url:null,
    header: item['_embedded']['wp:featuredmedia'].length > 0 ?
        item['_embedded']['wp:featuredmedia'][0].media_details.sizes['full'].source_url:null
  }
};
export default new Vuex.Store({
  state: {
    posts: [],
    post: null
  },
  getters: {
    formattedPosts(state){
      return state.posts.map(item => formattedPost(item));
    },
    formattedPost(state){
      return state.post ? formattedPost(state.post) : null
    },
  },
  actions: {
    fetchPosts({commit}, params){
      return new Promise((resolve, reject)=>{
        axios.get("https://blog.traxporta.com/wp-json/wp/v2/posts",{params:{...params, _embed: true}})
            .then(response => {
              commit('setPosts', response.data);
              resolve(response.data);
            })
            .catch(error =>{
              reject(error);
            });
      });
    },
    getPost({commit}, params){
      return new Promise((resolve, reject)=>{
        axios.get("https://blog.traxporta.com/wp-json/wp/v2/posts/"+params.id+"?_embed=true")
            .then(response => {
              commit('setPost', response.data);
              resolve(response.data);
            })
            .catch(error =>{
              reject(error);
            });
      });
    }
  },

  mutations: {
    setPosts(state, data){
      state.posts = data;
    },
    setPost(state, data){
      state.post = data;
    }
  },

});
