<template>
<div>
  <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2>Unidades disponibles cuando lo necesites</h2>
          <p>Miles de conductores están listos para transportar tu carga ofreciendo el mejor servicio.</p>
        </div>
      </div>
    </div>

    <div class="container mt-5" style="margin-bottom: 70px;">
      <div class="row">
        <div class="col-md-4" >
          <div class="icon-col">
            <img class="img-store" src="@/assets/images/6916cee2-3a43-469d-88c3-c523153a88e4.png" style="width: 44px"/>
          </div>
          <h6 class="mt-3">Entregas a tiempo</h6>
          <p class="text-muted mt-3">Viajes en tiempos establecidos con operadores profesionales y calificados.</p>
        </div>
        <div class="col-md-4">
          <div class="icon-col">
            <img class="img-store" src="@/assets/images/666116f5-4690-4362-9a5c-f0cc01a30863.png" style="width: 44px"/>
          </div>
          <h6 class="mt-3">Transportistas Calificados</h6>
          <p class="text-muted mt-3">El transporte y cuidado que tu carga requiere para llegar en excelente estado.</p>
        </div>
        <div class="col-md-4">
          <div class="icon-col">
            <img class="img-store" src="@/assets/images/ac70d331-bea4-40f7-9e1a-93f57bb4cb1d.png" style="width: 44px"/>
          </div>
          <h6 class="mt-3">Monitoreo de carga</h6>
          <p class="text-muted mt-3">Conoce a tu chofer y sigue en tiempo real la ruta de entrega a través de la aplicación.</p>
        </div>
      </div>
    </div>

    <div class="bg-body mt-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 d-none d-md-block text-center">
            <img style="height:400px;margin-top: -50px;" src="@/assets/images/Bloque-Mobile-Embarcadoras.png" />
          </div>
          <div class="col-md-6 py-4">
            <h2 class="mt-3 text-white">Cargas en tiempo real</h2>
            <p class="text-white mt-3">
              Publica todas las cargas que necesitas enviar. Miles de transportistas que cumplen con los requerimentos esperan por tu carga. 
            </p>
            <a href="https://web.traxporta.com/register/index" target="_blank" type="button" class="btn" style="background-color: #b8db15">Regístrate</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 mt-5 complete-block">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img class="img-store w-100" src="@/assets/images/Imagen_Bloque_1.png" />
        </div>
        <div class="col-md-6">
          <h2>Tarifas justas</h2>
          <p class="text-muted">Publica tus cargas y nosotros generaremos una tarifa justa y atractiva para los miles de transportistas que esperan por tu mercancía.</p>
          <div>
            <a href="https://web.traxporta.com/register/index" target="_blank" type="button" class="btn" style="background-color: #b8db15">Regístrate</a>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h2>Tú eliges quién transporta tu carga</h2>
          <p class="text-muted">Con un solo clic puedes verificar el número y estado de la flota del proveedor ¡Elije a tu favorito!</p>
          <div>
            <a href="https://web.traxporta.com/register/index" target="_blank" type="button" class="btn" style="background-color: #b8db15">Regístrate</a>
          </div>
        </div>
        <div class="col-md-6">
          <img class="img-store" src="@/assets/images/40b5fe2a-6649-423c-86e9-42b0c6472d7e.png" style="width: 100%;"/>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img class="img-store w-100" src="@/assets/images/truck.jpg" />
        </div>
        <div class="col-md-6">
          <h2>Control total en tu mano</h2>
          <p class="text-muted">Planea el embarque de tu mercancía, comunícate con el conductor, monitorea el estado de tu carga en todo momento sin salir de la aplicación.</p>
          <div>
            <a href="https://web.traxporta.com/register/index" target="_blank" type="button" class="btn" style="background-color: #b8db15">Regístrate</a>
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="margin-top: 100px;">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h2>Mejora la gestión de tus viajes</h2>
          <p class="text-muted">Haz más efectivos tus recursos y organiza tus entregas de acuerdo a tus tiempos y necesidades.</p>
          <div>
            <a href="https://web.traxporta.com/register/index" target="_blank" type="button" class="btn" style="background-color: #b8db15">Regístrate</a>
          </div>
        </div>
        <div class="col-md-6">
          <img class="img-store w-100" src="@/assets/images/7ff689d0-9baa-4d8f-a655-d683b72cc6f4.png" />
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img class="img-store w-100" src="@/assets/images/e9f6b7e4-ad48-4b19-a4f4-2eb9e2c32561.png" />
        </div>
        <div class="col-md-6">
          <h3>Beneficios</h3>
          <div class="mt-4">
            <table>
              <tr>
                <td class="text-center">
                    <img class="img-store" src="@/assets/images/17dcb656-931c-47b0-a013-d3224d7fbe54.png" style="height: 30px;" />
                </td>
                <td class="px-md-3">
                  <h6>Tarifas preferenciales</h6>
                  <p class="text-muted">
                    de acuerdo a la frecuencia de envío.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <img class="img-store" src="@/assets/images/92d6ab58-9c57-45f0-a203-d77613f41979.png" style="width: 40px;"/>
                </td>
                <td class="px-md-3">
                  <h6>Contacto directo</h6>
                  <p class="text-muted">
                    y personal por parte del equipo de Traxporta.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <img class="img-store" src="@/assets/images/63be5e10-4729-435c-9c5a-e3c6b4fdce25.png" style="height: 40px;"  />
                </td>
                <td class="px-md-3">
                  <h6>Capacitación</h6>
                  <p class="text-muted">de mejoras logísticas.</p>
                </td>
              </tr>
            </table>
          </div>
          <div class="mt-2">
            <a href="https://web.traxporta.com/register/index" target="_blank" type="button" class="btn" style="background-color: #b8db15">Regístrate</a>
          </div>

        </div>
      </div>
    </div>

    <div class="container mt-5">
      <h2 class="text-center">Preguntas Frecuentes</h2>
    </div>

    <div class="container mt-5 mb-5">
      <div class="row justify-content-md-center">
        <div class="col-md-10 offset-md-1">
          <div id="accordion">
            <div class="card">
              <div class="card-header collapsed" id="headingOne"  data-toggle="collapse" data-target="#collapseOne" aria-controls="collapseOne">
                <h5 class="mb-0">
                  <button class="btn btn-link btn-collapse">
                    ¿QUIÉN SE HACE RESPONSABLE SI ALGO LE SUCEDE A MI CARGA?
                  </button>
                </h5>
              </div>

              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                  Pondremos todos los recursos de Traxporta y te ofreceremos la mejor solución a nuestro alcance. Recuerda que nosotros garantizamos el servicio, sin embargo, el seguro y garantía corre por tu cuenta.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header collapsed" id="headingTwo"  data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <h5 class="mb-0">
                  <button class="btn btn-link btn-collapse collapsed">
                    ¿CÓMO ES EL PROCESO DE PAGO?
                  </button>
                </h5>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body">
                  Para mayor comodidad y seguridad de nuestros socios solo se aceptan pagos por medio de transferencia y a través de una factura.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header collapsed" id="headingThree"  data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <h5 class="mb-0">
                  <button class="btn btn-link btn-collapse collapsed">
                    ¿QUÉ PASA SI NADIE TOMA MI CARGA?
                  </button>
                </h5>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body">
                  En Traxporta existen miles de conductores listos para transportar las cargas de los embarcadores. En caso de que no tomen tu carga nosotros te ayudaremos a localizar tu transporte ideal.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header collapsed" id="headingFour"  data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <h5 class="mb-0">
                  <button class="btn btn-link btn-collapse collapsed">
                    ¿CÓMO PUEDO CONOCER A LA EMPRESA QUE ME DARÁ EL SERVICIO?
                  </button>
                </h5>
              </div>
              <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                <div class="card-body">
                  Podrás conocer a tu transportista a través de la aplicación. Recuerda que todos los transportistas y camiones son previamente revisados por el equipo de Traxporta para que cumplan con todos los requerimientos necesarios y así poder transportar las cargas en optimas condiciones.
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

</div>
</template>

<script>
import Blog from "../components/Blog"

export default {
  name: "Shipper",
  components: {
    Blog
  }
}
</script>

<style scoped>
.text-muted{
  color: #888888;
}
.bg-body{
  background: url('../assets/images/BG-Bloque-Embarcadoras.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.bg-truck{
  background: url('../assets/images/e9f6b7e4-ad48-4b19-a4f4-2eb9e2c32561.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 500px;
}

@media (min-width: 768px) {
  .bg-body{
    height: 350px;
  }
  .bg-truck{
    height: 350px;
  }
  .body-loads{
    height: 450px;
    margin-top: -50px;
  }
}
@media (max-width: 767px) {
  .bg-body{
  }
  .bg-truck{
  }
  .body-loads{
    height: 300px;
  }
}

.card-header{
  cursor: pointer;
  background-color: #fff;
  color: black;
}

.card-header > h5 > button{
  color:rgb(184, 219, 21);
}

.collapsed > h5 > button{
  color:rgb(0, 0, 0);
} 

.collapsed{
  cursor: pointer;
  background-color: #fff;
}

.btn-collapse{
  font-weight: 800;
  text-align: left;
}

.btn-link:hover {
  color:rgb(184, 219, 21);
  text-decoration: none;
}

.btn-link:focus{
  text-decoration: none;
}

.btn:focus{
  outline: none !important;
  box-shadow: none;
}

.complete-block{
  padding-left: 2rem;
  padding-right: 2rem;
}
</style>