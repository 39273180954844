import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from "../views/Home";
import Carrier from "../views/Carrier";
import Shipper from "../views/Shipper";
import ThankYou from "../views/ThankYou";
import Politics from "../views/Politics";
import BlogDetail from "../views/BlogDetail";
import Acerca from "../views/Acerca";
import TerminosLegales from "../views/TerminosLegales";
import Privacy from "../views/Privacy";
import Page404 from "../views/Page404";
import Quality from "@/views/Quality";

Vue.use(VueRouter)

const isServer = typeof window === 'undefined';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/carrier',
    name: 'carrier',
    component: Carrier
  },
  {
    path: '/shipper',
    name: 'shipper',
    component: Shipper
  },
  {
    path: '/shipper/thank-you',
    name: 'shipper.thank-you',
    component: ThankYou
  },
  {
    path: '/carrier/thank-you',
    name: 'carrier.thank-you',
    component: ThankYou
  },
  {
    path: '/politica-de-privacidad',
    name: 'politica',
    component: Politics
  },
  {
    path: '/politica-de-calidad',
    name: 'politica-calidad',
    component: Quality
  },
  {
    path:'/blog/posts/:post_id/:post_slug',
    name:'blogdetail',
    component: BlogDetail
  },
  {
    path: '/acerca-de-la-compania',
    name: 'acerca',
    component: Acerca
  },
  {
    path: '/terminos-legales',
    name: 'terminos-legales',
    component: TerminosLegales
  },
  {
    path: '/aviso-de-privacidad',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/verified-email/:status',
    name: 'verified-email',
  },
  {
    path: '/404',
    name: 'page404',
    component: Page404
  },
  {
    path: '*',
    redirect: '/404'
  }
]

export function createRouter () {
  const router = new VueRouter({
    mode: 'history',
    routes: routes
  })

  if(isServer === false){
    router.beforeEach((to, from, next) => {
      window.scrollTo(0, 0)
      next();
    })
  }
  return router;
}
