import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import {createRouter} from './router'
import store from './store'
import { sync } from 'vuex-router-sync'

import "@/assets/scss/app.scss";

require('./bootstrap');

Vue.use(VueMeta);
Vue.config.productionTip = false

export default function createApp() {
  const router = createRouter();
  sync(store, router);
  const app = new Vue({
    router,
    store,
    render: h => h(App)
  });
  return {app, router, store}
}
