<template>
  <div id="blog" style="background: #f7f7f7">
      <div class="container p-5">
        <div class="text-center mb-4">
          <h3>Blog</h3>
        </div>

        <div class="row">
				
			<div class="col-md-4" v-for="(item, index) in formattedPosts"  :key="item.id">
				<div class="card card-blog">
					<div class="card-image">
            <a href="javascript:void(0)" @click="showPost(item)">
              <img v-if="item.image" width="360" height="240" :src="item.image"
                   class="attachment-hestia-blog size-hestia-blog wp-post-image" alt="">
            </a>
					</div>
					<div class="content">
						<h6 class="category text-info">{{item.categories.length > 0 ? item.categories[0].name : ''}}</h6>
						<h4 class="card-title">
              <a href="javascript:void(0)" @click="showPost(item)">
                {{item.title}}
              </a>
            </h4>
						<p class="card-description" v-html="item.content"></p>
            <a href="javascript:void(0)" @click="showPost(item)">
              Leer más…
            </a>
					</div>
				</div>
			</div>
		</div>


      </div>

    </div>
</template>

<script>

import {mapActions, mapState, mapGetters, mapMutations} from 'vuex'

export default {
  name: "Blog",
  component:{
  },
  data(){
      return {
        blog_data: [],
        blog_details: []
      }
  },
  computed: {
    ...mapState(['posts']),
    ...mapGetters(['formattedPosts'])
  },
  mounted() {
    this.fetchPosts({per_page: 3})
        .then(data =>{

        })
        .catch(error =>{

        })
  },
  methods:{
    ...mapActions(['fetchPosts']),
    ...mapMutations(['setPost']),
    showPost(item){
      this.setPost(this.posts.find(el => el.id === item.id));
      this.$router.push({name:'blogdetail',params:{post_id: item.id, post_slug: item.slug}});
    }
  }
}
</script>

<style scoped>
    .card-blog {
        margin-top: 30px;
    }

    .card {
        display: inline-block;
        position: relative;
        width: 100%;
        margin-bottom: 30px;
        border-radius: 6px;
        color: rgba(0, 0, 0, 0.87);
        background: #fff;
        -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    }

    .card .card-image{
        -webkit-box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
        -moz-box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
        box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
    }

    .text-info > a {
        color: #00bcd4;
    }

    .card-title > a {
        color: #000;
        text-decoration: none;
    }

    a.moretag {
        color: #000;
        display: inline-block;
        text-decoration: none;
    }

    a.moretag:hover {
        color: #00bcd4;
    }

    .card-blog .card-title {
        margin-top: 5px;
        font-size: 18.2px;
        line-height: 25.48px;
    }

    .card .content {
        padding: 15px 30px;
    }

    .card-description{
        color: #999999;
    }

    .card .card-image {
        overflow: hidden;
        position: relative;
        height: 60%;
        margin-top: -30px;
        margin-right: 15px;
        margin-left: 15px;
        border-radius: 6px;
        -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
        -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
        -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
        -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
        transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .card .card-image img {
        float: left;
        width: 100%;
        height: 100%;
        margin: 0 !important;
        border-radius: 6px;
    }
</style>