<template>
  <div class="row align-items-center">
    <div class="col-md-12 text-center">
      <h1>{{title}}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalizedHeader",
  props: ['title'],
}
</script>

<style scoped>
.page-header h1{
  color:white;
  margin-top: 8rem;
  margin-bottom: 4rem;
}
.page-header p{
  color:white;
}

p{
  font-size: 1.2rem;
}
.img-store{
  width:150px;
}
.mockup{
  width: 400px;
  margin-bottom: -160px;
}
</style>