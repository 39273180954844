<template>
<footer id="download" class="footer" :class="$route.name === 'carrier.thank-you' ? 'fixed-bottom' : ''">
    <div class="container">
        <div class="row p-5">
            <div class="col-md-3 col-sm-12 my-3 text-left">
                <p class="h5 font-weight-bold">Datos de contacto</p>
                
                <div class="pt-3 row">
                    <div class="col-1 d-flex align-items-center">
                        <i class="fas fa-map-marker-alt fa-1x"></i>
                    </div>
                    <div class="col-8">
                        <small>
                            Calle Industrial Nacional 1, Alce Blanco 53370, Naucalpan de Juárez, Estado de México, México
                        </small>
                    </div>
                </div>

                <div class="pt-3 row">
                    <div class="col-1">
                        <i class="fas fa-phone-alt fa-1x"></i>
                    </div>
                    <div class="col-8">
                        <small>(+1) 55 3148 9253</small>
                    </div>
                </div>

                <div class="pt-3 row">
                    <div class="col-1">
                        <i class="fas fa-phone-alt fa-1x"></i>
                    </div>
                    <div class="col-8">
                        <small>(+1) 55 4180 3616</small>
                    </div>
                </div>

                <div class="pt-3 row">
                    <div class="col-1">
                        <i class="fas fa-phone-alt fa-1x"></i>
                    </div>
                    <div class="col-8">
                        <small>(+1) 55 7113 0751</small>
                    </div>
                </div>

                <div class="pt-3 row">
                    <div class="col-1">
                        <i class="fas fa-envelope fa-1x"></i>
                    </div>
                    <div class="col-8">
                        <small>atencioncx@traxion.global</small>
                    </div>
                </div>

                <div class="pt-3">
                    <a href="https://www.facebook.com/Traxporta-113402894120812" target="_blank"><img class="mr-3" src="../assets/images/rectangle_800.png" alt=""></a>
                    <a href="https://www.linkedin.com/company/traxporta" target="_blank"><img src="../assets/images/174857.png" alt=""></a>
                </div>                   
            </div>
            <div class="col-md-3 col-sm-12 my-3 text-left">
                <p class="h5 font-weight-bold">Compañía</p>
                
                <ul class="p-0">
                    <li>
                        <small>
                            <router-link class="link" to="/acerca-de-la-compania">Acerca de la compañía</router-link>
                        </small>
                    </li>
                    <li>
                        <small>
                            <a class="link" href="/#aliados">Aliados</a>
                        </small>
                    </li>
                    <li>
                        <small>
                            <router-link class="link" to="/terminos-legales">Términos legales</router-link>
                        </small>
                    </li>
                    <li>
                        <small>
                            <router-link class="link" to="/politica-de-privacidad">Política de privacidad</router-link>
                        </small>
                    </li>
                    <li>
                        <small>
                            <router-link class="link" to="/politica-de-calidad">Política de calidad</router-link>
                        </small>
                    </li>
                    <li>
                        <small>
                            <router-link class="link" to="/aviso-de-privacidad">Aviso de privacidad</router-link>
                        </small>
                    </li>
                </ul>                 
            </div>
            <div class="col-md-5 col-sm-12 my-3 text-left">
                <p class="h5 font-weight-bold">¡Únete a la comunidad de Traxporta hoy!</p>
                <small style="color:#888888">
                    Traxporta web, más opciones, en menos tiempo. Fácil ingreso desde  computadoras o tabletas.
                    <br>Traxporta móvil, sin importar donde te encuentres, cargas y transportadores al instante.
                </small>
                <div class="row my-3">
                    <div class="col-12 p-0">
                        <a href="https://apps.apple.com/mx/app/traxporta/id1509693819" target="_new">
                            <img style="width: 150px" src="../assets/images/app_store.png" alt="">
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=global.traxion.traxporta&hl=es_MX&gl=US" target="_new">
                            <img style="width: 150px" src="../assets/images/google_play.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer {
  background-color: #e0e0e0;
}

.footer li {
    list-style:none;
    padding-top: 10px;
}

.link { color: #000000; text-decoration: none; } /* CSS link color (red) */
.link:hover { color: #00aec3; } /* CSS link hover (green) */

</style>