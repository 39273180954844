import { render, staticRenderFns } from "./Page404.vue?vue&type=template&id=9f962b5c&scoped=true&"
import script from "./Page404.vue?vue&type=script&lang=js&"
export * from "./Page404.vue?vue&type=script&lang=js&"
import style0 from "./Page404.vue?vue&type=style&index=0&id=9f962b5c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f962b5c",
  null
  
)

export default component.exports