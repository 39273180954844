<template>
    <div class="container">
        <div class="row">
            <div class="col-8 offset-2 mb-5 mt-5 text-center">
                <h2 class="txt-traxporta">404</h2>
                <br>
                <h4 class="not-found">Página no encontrada</h4>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "Page404"
}
</script>

<style scoped>
    h2.txt-traxporta {
        display: inline-block;
        color: #000;
        background: #fff;
        mix-blend-mode: multiply;
        position: relative;
        font-size: 150px;
        margin-top: 2rem;
    }

    h2.txt-traxporta:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(to left, #b8db15, #1db2e6);
        mix-blend-mode: screen;
        position: absolute;
        top: 0;
        left: 0;
    }

    h4.not-found{
        color: #aaaaaa;
        margin-bottom: 5rem;
    }
</style>