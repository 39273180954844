<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="text-center">
          <p class="text-muted">Sin turnos para asignación de cargas, sin recargos adicionales, en un proceso rápido, transparente y tecnológico. El pago puede ser recibido incluso en menos de 7 días después de recibir evidencias físicas.</p>
        </div>
      </div>
    </div>

   <div class="container mt-5" style="margin-bottom: 70px;">
      <div class="row">
        <div class="col-md-3">
          <img class="img-store" src="@/assets/images/Cero-intermediarios.png" />
          <h6 class="mt-3">Pagos inmediatos en nuestras cargas Premium</h6>
          <p class="text-muted mt-3">Pagos en menos de 7 días después de recibir evidencias físicas, cuando lo necesites y para lo que necesites.</p>
        </div>
        <div class="col-md-3">
          <img class="img-store" src="@/assets/images/Miles-de-cargas.png" />
          <h6 class="mt-3">Cero intermediarios</h6>
          <p class="text-muted mt-3">Traxporta hace todo por ti, documentación enviada, pago recibido, sin largos procesos, sin más intermediarios.</p>
        </div>
        <div class="col-md-3">
          <img class="img-store" src="@/assets/images/Informacion.png" />
          <h6 class="mt-3">Amplia oferta</h6>
          <p class="text-muted mt-3">Ya sea una o varias cargas a enviar, miles de transportadores dispuestos a ayudar.</p>
        </div>
        <div class="col-md-3">
          <img class="img-store" src="@/assets/images/666116f5-4690-4362-9a5c-f0cc01a30863.png" width="15%"/>
          <h6 class="mt-3">Información al instante</h6>
          <p class="text-muted mt-3">¿Nuevas cargas para transportar? ¿Cargas para asignar? Notificaciones al instante.</p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="text-center">
          <h3>Cargas a un clic de distancia. Busca como quieras, cuando quieras. Miles de cargas esperan por ti.</h3>
        </div>
      </div>
    </div>

    <div class="bg-body mt-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 py-4">
            <h2 class="mt-3 text-white">No importa donde estés,Traxporta lo hace más fácil.</h2>
            <p class="text-white mt-3">
              Traxporta web, más opciones, en menos tiempo. Fácil ingreso desde computadoras o tabletas.Traxporta móvil, sin importar donde te encuentres, cargas y transportadores al instante.
            </p>
            <a href="https://web.traxporta.com/register/index" target="_blank" type="button" class="btn" style="background-color: #b8db15">Únete</a>
          </div>
          <div class="col-md-6 text-center">
            <img class="body-loads" src="@/assets/images/traxporta-768x571.png" />
          </div>
          
        </div>
      </div>
    </div>
    
    <div class="container mt-5">
        <div class="text-center">
          <h3>Características adicionales</h3>
        </div>
    </div>

    <div class="mt-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 py-4">
            <h6>Pagos inmediatos</h6>
            <p class="text-muted">¿30 a 90 días para recibir el pago? Es tiempo pasado. Con Traxporta pagos en menos de 7 dias en nuestras cargas Premium.</p>
            <h6>Fácil reserva</h6>
            <p class="text-muted">Reserva al instante. Recibe confirmación sin preocuparte.</p>
            <h6>Precio real</h6>
            <p class="text-muted">Mira el precio de la carga. Precio escogido. Dinero recibido. Sin sorpresas</p>
            <h6>Ruta Completa</h6>
            <p class="text-muted">Regresas con tu camión desocupado? Pierdes dinero por viajes vacíos? Con Traxporta es tiempo pasado. Planea tu ruta, tu regreso y haz tu viaje rentable.</p>
          
            <a href="https://web.traxporta.com/register/index" target="_blank" type="button" class="btn" style="background-color: #b8db15">Únete</a>
          </div>
          <div class="col-md-6 text-center">
            <img class="body-loads" src="@/assets/images/screen-dashboard-webapp.png" />
          </div>
          
        </div>
      </div>
    </div>

    <div class="container mt-5">
        <div class="text-center">
          <h3>Lo que dicen de nosotros</h3>
        </div>
    </div>

    <div style="background: #f7f7f7">
      <div class="container p-5">
        <div class="row">
          <div class="col-md-4 p-3">
            <div class="card" style="width: 100%;">
              <div class="card-body">
                <div class="text-center my-3">
                  <img src="@/assets/images/40f94035-052d-4375-b9b6-8eb7fcf19ef6.png" style="width: 100px"/>
                </div>
                <p class="card-text">
                  “El envío de documentación digital me ahorra mucho tiempo, solo les tomo fotos y envío. No importa donde me encuentre"
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 p-3">
            <div class="card" style="width: 100%;">
              <div class="card-body">
                <div class="text-center my-3">
                  <img src="@/assets/images/c0a3f1d2-196a-43e9-8d96-909ad2e095f6.png" style="width: 100px"/>
                </div>
                <p class=" card-text">
                  "Ya no tengo que esperar 30 días para recibir el pago, si necesito dinero para combustible puedo tenerlo fácilmente, no hay muchas preguntas."
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 p-3">
            <div class="card" style="width: 100%;">
              <div class="card-body">
                <div class="text-center my-3">
                  <img src="@/assets/images/515aef60-d7f6-4143-8b0b-d1dd5c845873.png" style="width: 100px"/>
                </div>
                <p class="card-text">
                  "La solución Web es excelente, cuando necesito administrar muchas cargas con mi equipo de trabajo me ahorra mucho tiempo."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    
    <div id="aliados" style="background: #f7f7f7">
      <div class="container py-5">
        <div class="text-center py-5">
          <img src="@/assets/images/traxion-1.png" style="max-width:300px; max-height: 150px;"/>
        </div>
        <div>

        </div>
        <div class="row align-items-center text-center">
          <div class="col-md-3 col-sm-6 my-4"><img class="icon-partner" src="@/assets/images/mym-2.png" /></div>
          <div class="col-md-3 col-sm-6 my-4"><img class="icon-partner" src="@/assets/images/egoba-2.png" /></div>
          <div class="col-md-3 col-sm-6 my-4"><img class="icon-partner" src="@/assets/images/afn-2.png" /></div>
          <div class="col-md-3 col-sm-6 my-4"><img class="icon-partner" src="@/assets/images/bisonte-2.png" /></div>
        </div>

         <div class="row align-items-center text-center ">
          <div class="col-md-3 col-sm-6 my-4"><img class="icon-partner" src="@/assets/images/grupo-sid-2.png" /></div>
          <div class="col-md-3 col-sm-6 my-4"><img class="icon-partner" src="@/assets/images/redpack-2.png" /></div>
          <div class="col-md-3 col-sm-6 my-4"><img class="icon-partner" src="@/assets/images/lipu-2.png" /></div>
          <div class="col-md-3 col-sm-6 my-4"><img class="icon-partner" src="@/assets/images/TRAXI_LOGOTIPO_01.png" ></div>
        </div>

        <div class="text-center">
          <img src="@/assets/images/traxion-2.png" style="max-width:250px; max-height: 150px;"/>
        </div>

      </div>

    </div>

    <!-- BLOG COMPONENT -->
    <blog />

  </div>
</template>

<script>
// @ is an alias to /src
import Blog from "../components/Blog"

export default {
  name: 'Home',
  components: {
    Blog
  },
  metaInfo: {
    title: 'Traxporta - La nueva forma de mover al mundo',
    meta: [
      { vmid: 'description', name: 'description', content: 'Sin turnos para asignación de cargas, sin recargos adicionales, en un proceso rápido, transparente y tecnológico. El pago puede ser recibido incluso en menos de 7 días después de recibir evidencias físicas.' }
    ]
  }
}
</script>

<style scoped>
.text-muted{
  color: #888888;
}
.bg-body{
  background: url('../assets/images/background-02.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.icon-partner{
  max-height:80px;
  max-width:150px;
}
@media (min-width: 768px) {
  .bg-body{
    height: 350px;
  }
  .body-loads{
    height: 450px;
    margin-top: -50px;
  }
}
@media (max-width: 767px) {
  .bg-body{}
  .body-loads{
    height: 200px;
  }
}
</style>
