<template>
  <div id="contact" class="contact">
    <div class="container">
      <div class="row text-center">
        <div class="col-12">
          <h3 class="title">¿Cuentas con una flotilla de transportes?</h3>
          <p class="pt-2">Contáctanos para recibir información adicional</p>
        </div>
        <div class="container">
          <form @submit.prevent="sendMailContact" >
            <div class="input-group mb-3">
              <div class="col-6">
                <input type="text" v-model="name" class="form-control" placeholder="Nombre">
                <small v-if="name==''" class="text-danger float-left pl-1">Campo obligatorio</small>
              </div>
              <div class="col-6">
                <input type="email" v-model="email" class="form-control" placeholder="Correo electrónico">
                <small v-if="email==''" class="text-danger float-left pl-1">Campo obligatorio</small>
              </div>
            </div>
            <div class="input-group mb-3">
            <div class="col-12">
              <input type="text" v-model="subject" class="form-control" placeholder="Asunto">
              <small v-if="subject==''" class="text-danger float-left pl-1">Campo obligatorio</small>
            </div>
            </div>
            <div class="input-group mb-3">
            <div class="col-12">
              <textarea class="form-control" v-model="message" placeholder="Mensaje"  name="" id="" cols="30" rows="5"></textarea>
              <small v-if="message==''" class="text-danger float-left pl-1">Campo obligatorio</small>
            </div>
            </div>
            <div class="d-flex justify-content-center text-center">
              <button class="btn btn-contact">Enviar Mensaje</button>
              <div v-if="loading" class="spinner-border text-primary ml-3" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
            <div v-if="sendSuccess" class="alert alert-success mt-3" role="alert">
                Su correo fue enviado correctamente!
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ContactForm",
  data(){
      return {
        name: null,
        email: null,
        subject : null,
        message: null,
        loading: false,
        sendSuccess: false
      }
  },
  methods: {
    sendMailContact(){
      let error = 0
      if (!this.name) { this.name = ''; error = 1; }
      if (!this.email) { this.email = ''; error = 1; }
      if (!this.subject) { this.subject = ''; error = 1; }
      if (!this.message) { this.message = ''; error = 1; }

      if (!error) {

        let types = {
          'carrier': 'Transportistas',
          'shipper': 'Embarcadores'
        }

        if (this.$route.name === 'carrier' || this.$route.name === 'shipper')
          this.subject = types[this.$route.name] + ': ' + this.subject
        
        this.loading = true
        fetch('https://api.traxporta.com/api/v2/send/mail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({name: this.name, email: this.email,subject:  this.subject, message: this.message})
          }).then(data => {
            console.log(data)
            this.loading = false
            this.resetInput()

            if (this.$route.name === 'carrier' || this.$route.name === 'shipper') {
                window.location.href = `/${this.$route.name}/thank-you`
            } else {
              this.sendSuccess = true
              setTimeout(() => {
                this.sendSuccess = false
              }, 2000);
            }
          })
      }

      return
    },
    resetInput() {
      this.name =  null
      this.email =  null
      this.subject  =  null
      this.message =  null
    }
  },
}
</script>

<style scoped>
.contact {
  background-color: #f7f7f7;
  padding-bottom: 45px;
}

.contact .title{
  padding-top: 0.8em;
  color: #222222;
}

.contact .btn-contact{
  background-image: linear-gradient(to right, #b8db15, #1db2e6);
  color: #f7f7f7;
  border-radius: 20px;
  font-size: 12px;
  width: 119.25px;
  height: 34.50px;
}

</style>