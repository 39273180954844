<template>
  <div class="container d-flex flex-column align-items-center mb-5">

    <p class="verification-title">Confirmación de correo electrónico</p>

    <div class="card">
      <div class="card-body d-flex flex-column align-items-center">
        <img class="nav-logo mb-4" src="@/assets/images/triangle.png" width="40" alt="Email Confirmation" />
        <p class="verification-message">
          <template v-if="this.$route.params.status === 'success'">
            Correo electrónico confirmado correctamente.
          </template>
          <template v-else>
            Se produjo un error al validar su correo electrónico.
          </template>
        </p>

        <button
            type="button"
            class="btn btn-azufre px-5"
            onclick="window.location = 'https://traxporta.com'"
        >
          Finalizar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "VerifiedEmail"
}
</script>

<style lang="css" scoped>
.verification-title {
  font-size: 1rem;
  font-family: "Helvetica Neue", sans-serif;
  margin: 5rem 0 1rem 0;
  font-weight: bold;
}
.card {
  padding: 1rem 2.75rem;
  border-radius: 1.25rem;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(169, 169, 169, 0.5);
}

.verification-message {
  font-size: 1rem;
  font-family: "Helvetica Neue" ,sans-serif;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2.125rem;
}

.btn{
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}

.btn-azufre {
  color: #434619;
  background-color: #E3ED55;
  border-color: #E3ED55;
}

.btn-azufre:hover {
  color: #434619;
  background-color: #dde932;
  border-color: #dbe827;
}

.btn-azufre:active, .btn-azufre:focus {
  color: #434619;
  background-color: #dbe827;
  border-color: #dae71b;
  box-shadow: 0 0 0 3px #f8ff91;
}

</style>
