import createApp from './app';

const { app, store } = createApp();

const storeInitialState = window.INITIAL_DATA;
if (storeInitialState) {
  store.replaceState(storeInitialState);
}


app.$mount('#app');