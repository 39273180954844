<template>
  <div class="row align-items-center">
    <div class="col-md-6 text-left">
      <div class="row">
        <div class="col-md-12">
          <h1>Embarca, transporta y organiza, en una sola app.</h1>
          <p class="mt-3">Conectamos transportistas y embarcadoras para lograr
            una movilidad segura y efectiva.</p>
          <p class="mt-3">Únete a <b>Traxporta</b>, la nueva forma de mover el mundo.</p>
        </div>
        <div class="col-md-12 px-0">
          <div>
            <a href="https://apps.apple.com/mx/app/traxporta/id1509693819" target="_blank"><img class="img-store" src="@/assets/images/app_store.png" /></a>
            <a href="https://play.google.com/store/apps/details?id=global.traxion.traxporta&hl=es_MX&gl=US" target="_blank"><img class="img-store" src="@/assets/images/google_play.png" /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 text-center">
      <img class="mockup" src="@/assets/images/app_mockup.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CarrierHeader",
  metaInfo() {
      return {
          title: 'TRANSPORTISTAS',
          meta: [
              { name: 'description', content: 'Conectamos transportistas y embarcadoras para lograr una movilidad segura y efectiva.'},
              { property: 'og:title', content: 'TRANSPORTISTAS'},
              { property: 'og:description', content: 'Conectamos transportistas y embarcadoras para lograr una movilidad segura y efectiva.'},
              { property: 'og:type', content: 'website'}
          ]
      }
  }
}
</script>

<style scoped>
.page-header h1{
  color:white;
}
.page-header p{
  color:white;
}

p{
  font-size: 1.2rem;
}
.img-store{
  width:150px;
}
.mockup{
  width: 400px;
  margin-bottom: -160px;
}
@media (max-width: 767px) {
  .mockup {
    max-width:100%;
    margin-bottom: 0;
  }}
</style>