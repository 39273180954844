<template>
  <div>
    <div v-if="post"  class="container">
      <div class="row">
        <div class="col-md-8 offset-2">
          <div v-html="post.content.rendered"></div>
        </div>
        <div class="col-md-4">

        </div>
      </div>
      <div class="row mt-5 mb-5 offset-2">
        <div class="section section-blog-info">
          <div class="row">
            <div class="col-md-6">
              <div v-if="post._embedded['wp:term'][0].length > 0" class="entry-categories">
                Categories:
                <span :key="'cat'+category.id" v-for="category in post._embedded['wp:term'][0]"
                      class="label label-primary">
                <a :href="category.link">
                  {{category.name}}
                </a>
              </span>
              </div>
              <div class="entry-tags">
                Tags:
                <span class="entry-tag mr-2" v-for="(tag, index) in post._embedded['wp:term'][1]"  :key="'tag_'+index">
                <a :href="tag.link" rel="tag">{{tag.name}}</a>
              </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="entry-social">
                <a target="_blank" rel="tooltip" data-original-title="Share on Facebook" class="btn btn-just-icon btn-round btn-facebook"
                   :href="'https://www.facebook.com/sharer/sharer.php?u='+post.link"><i class="fa fa-facebook"></i></a>
                <a target="_blank" rel="tooltip" data-original-title="Share on Twitter" class="btn btn-just-icon btn-round btn-twitter"
                   :href="'https://twitter.com/home?status='+post.title.rendered+' - '+post.link"><i class="fa fa-twitter"></i></a>
                <a target="_blank" rel="tooltip" data-original-title=" Share on Google+" class="btn btn-just-icon btn-round btn-google"
                   :href="'https://plus.google.com/share?url='+post.link"><i class="fa fa-google"></i></a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
  name: "BlogDetail",
  metaInfo() {
    return {
      title: this.post ? this.post.title.rendered : 'Traxporta',
      meta: this.post ? [
        { vmid: 'description', name: 'description', content: this.post.content.rendered.substr(0, 200).replace(/(<([^>]+)>)/gi, "") + '...' },
        { property: 'og:title', content: this.post.title.rendered},
        { property: 'og:description', content: this.post.content.rendered.substr(0,300).replace(/(<([^>]+)>)/gi, "") + '...' },
        { property: 'og:site_name', content: 'Traxporta'},
        { property: 'og:type', content: 'website'},
        { property: 'og:image', content: this.post['_embedded']['wp:featuredmedia'].length > 0 ?
              this.post['_embedded']['wp:featuredmedia'][0].media_details.sizes['hestia-blog'].source_url:null }
      ]: []
    }
  },
  data(){
    return {
      blog_detail: null,
      tags: []
    }
  },
  serverPrefetch() {
    return this.fetchData();
  },
  computed:{
    ...mapState(['post'])
  },
  mounted() {
    if(!this.post)
      this.fetchData();
  },
  methods: {
    ...mapActions(['getPost']),
    fetchData(){
      return this.getPost({id: this.$route.params.post_id});
    }
  }
}
</script>

<style scoped>

</style>