<template>
    <div class="container page-post">
<div class="row">
							<div class="col-8 offset-2 mb-5">
								<p>&nbsp;</p>
                                    <p><strong>1. Identidad y Domicilio del Responsable del tratamiento de sus datos personales.</strong></p>
                                    <p><strong>Traxion Technologies, S.A. de C.V.</strong> (denominada en lo sucesivo el “<strong><u>Responsable</u></strong>”), es responsable de recabar sus datos personales, del tratamiento que se le dé a los mismos y de su protección.</p>
                                    <p>Datos del contacto del Responsable:</p>
                                    <ul>
                                    <li><strong>Domicilio:</strong> Calle Industrial Nacional 1, Alce Blanco 53370 Naucalpan de Juárez, Estado de México, México.</li>
                                    <li><strong>Correo electrónico</strong>: <a href="mailto:datospersonales@traxporta.com">datospersonales@traxporta.com</a></li>
                                    <li><strong>Teléfono: </strong>(55) 6898 1656.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <p><strong>2. Finalidades del tratamiento de los datos personales.</strong></p>
                                    <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:</p>
                                    <ul>
                                    <li>Unir de manera eficiente las necesidades del Titular para transportar su mercancía a un punto por medio del Transportista a través de la Plataforma digital e informática denominada Traxporta (en lo sucesivo la “Plataforma”) que es un motor de interconexión e interacción en tiempo real entre los Titular y los Transportistas, así como a la aplicación móvil y a través de cualquiera de los medios digitales de “Traxporta”.</li>
                                    <li>Crear y actualizar las cuentas de los usuarios en la Plataforma, así como a la aplicación móvil y a través de cualquiera de los medios digitales de “Traxporta”.</li>
                                    <li>Contactar con el Titular para la asesoría y colocación de servicios logísticos para el transporte y almacenamiento de mercancía</li>
                                    <li>Brindar soporte y asistencia al Titular.</li>
                                    <li>Verificar la identidad, antecedentes y cumplimiento de obligaciones de los requisitos legales.</li>
                                    <li>Se autorice(n) línea(s) de crédito comercial.</li>
                                    <li>Permitir servicios de transporte, entregas y otros servicios relacionados a través de los medios digitales de Traxporta.</li>
                                    <li>Dar seguimiento de contacto, previo, durante y después de la entrega de la mercancía transportada.</li>
                                    <li>Mejorar los servicios al cliente.</li>
                                    <li>Permitir comunicación entre usuarios.</li>
                                    <li>Generar expediente del Titular para la prestación del servicio de logística para el transporte de mercancías a través de terceros.</li>
                                    <li>Generar el contrato que brinda un espacio neutral digital que facilite el encuentro de la oferta y la demanda del mercado de transporte de carga.</li>
                                    <li>Ofrecer, procesar o facilitar pagos de nuestros servicios.</li>
                                    <li>Generar estados de cuenta y saldos y emitir Códigos de Pago, Cartas Porte, Facturas, referencia bancaria para el depósito y Comprobante Fiscal Digital.</li>
                                    <li>Activar las funciones de accesibilidad y para personalizar cuentas en la Plataforma</li>
                                    <li>Abordar temas relacionados con procedimientos legales.</li>
                                    <li>Contactar al Titular para realizar cobranza.</li>
                                    <li>Realizar operaciones internas necesarias para brindar nuestros servicios o productos.</li>
                                    <li>Llevar investigación y desarrollo para realizar pruebas, análisis, desarrollo de productos y aprendizaje automático con el fin de mejorar su experiencia.</li>
                                    <li>Hacer llegar comunicaciones no comerciales a fin de informales sobre cambios en nuestros términos y condiciones, servicios o políticas o enviar otras comunicaciones que no tienen el propósito de comercializar los servicios o productos del Responsable.</li>
                                    <li>Toma de decisiones automatizadas en relación con el uso de nuestros servicios, incluyendo precio, asignación de transportistas, calificaciones a transportistas, desactivación de cuentas de usuarios involucrados en fraude, actividades ilícitas, o cualquier otra actividad que pudiera dañar a la plataforma.</li>
                                    </ul>
                                    <p>Adicionalmente, utilizaremos su información personal para las siguientes finalidades que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan una mejor atención:</p>
                                    <p>A. Enviar comunicaciones de marketing de servicios o productos por parte del Responsable.</p>
                                    <p>B. Invitarlo a eventos relacionados con las actividades del Responsable.</p>
                                    <p>Como Titular tiene un plazo de cinco (5) días hábiles para que, de ser el caso, manifieste su negativa para el tratamiento de sus datos personales con respecto a las finalidades secundarias antes señaladas, en los términos y condiciones señalados en los numerales 6, 7 y 8 siguientes. Quedan a salvo los derechos de usted como Titular, para ejercer sus derechos de revocación del consentimiento u oposición, en caso de que no manifieste la negativa para el tratamiento de sus datos personales previo a la entrega de los mismos o de su aprovechamiento o utilización.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>3. Datos personales que recabamos.</strong></p>
                                    <p>Podemos recabar sus datos personales para las finalidades antes citadas, de distintas formas: cuando usted nos lo proporciona directamente, o bien obteniéndolos de manera indirecta, a través de otras fuentes que se encuentran permitidas por la Ley y el Reglamento.</p>
                                    <p>Recabamos sus datos personales de forma directa cuando usted mismo nos lo proporciona en persona, al requisitar un formulario impreso o digital, por correo electrónico, a través de llenado de un formato en línea, apps, sitios web (vía internet), o telefónicamente.</p>
                                    <p>Los datos que obtenemos por estos medios son datos personales de identificación y contacto, a continuación, se enlistan los datos personales que se pueden recabar:</p>
                                    <ul>
                                    <li>Nombre completo.</li>
                                    <li>Correo electrónico.</li>
                                    <li>Teléfonos.</li>
                                    <li>RFC.</li>
                                    <li>CURP.</li>
                                    <li>Domicilio completo.</li>
                                    <li>País de residencia.</li>
                                    <li>Fecha de nacimiento.</li>
                                    <li>Lugar de nacimiento.</li>
                                    <li>País de Nacimiento</li>
                                    <li>Nacionalidad.</li>
                                    <li>Actividad, ocupación o profesión, en su caso.</li>
                                    <li>Datos de Identificación Oficial y vigente (credencial de elector, licencia o pasaporte vigente).</li>
                                    <li>Número de seguridad social, en su caso.</li>
                                    <li>Estado civil, en su caso.</li>
                                    <li>Nivel de estudios, en su caso.</li>
                                    <li>Ubicación precisa o aproximada de sus dispositivos, siempre y cuando el Titular lo permita en la plataforma y medios digitales, en el caso de que no se permita que se recopilen los datos de ubicación de sus dispositivos se deberá ingresar manualmente el punto de recolección.</li>
                                    <li>Datos de uso de la plataforma.</li>
                                    <li>Firma.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <p><strong>4. Datos personales sensibles, financieros y patrimoniales que recabamos.</strong></p>
                                    <p>Asimismo, se recaban para algunos procesos específicos datos personales sensibles, financieros y patrimoniales. Los datos personales de esta naturaleza que se pueden recabar son los siguientes:</p>
                                    <ul>
                                    <li>Información de transacciones relacionadas con el uso de nuestros servicios (método de pago).</li>
                                    <li>Saldos de cuentas bancarias.</li>
                                    <li>Bancos asociados.</li>
                                    <li>Cuenta CLABE interbancaria.</li>
                                    <li>Número de cuenta.</li>
                                    <li>Ingresos mensuales brutos, en su caso.</li>
                                    </ul>
                                    <p>Dichos datos pueden ser recabados al Titular a través de cualquiera de los medios antes citados.</p>
                                    <p>De acuerdo a lo que establece el artículo 9 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, cuando el Responsable recabe de usted tales datos, le será solicitado su consentimiento expreso para el tratamiento de estos datos, por lo que le solicitaremos que, en su momento, indique si acepta o no el tratamiento, y en caso de aceptarlo, nos proporcione por escrito, a través de su firma autógrafa, firma electrónica, o cualquier mecanismo de autenticación que al efecto se establezca.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>5. Transferencias/remisiones de datos personales dentro de México y al extranjero.</strong></p>
                                    <p>Sus datos personales pueden ser transferidos/remitidos y tratados dentro y fuera del país, a autoridades y sociedades del Grupo, subsidiarias y/o afiliadas del Responsable. Nos comprometemos a no transferir su información personal a terceros sin su consentimiento, salvo las excepciones contempladas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, así como a realizar esta transferencia en los términos que fija dicha Ley. Es de señalar que para el cumplimiento del mantenimiento o cumplimiento de una relación jurídica entre el Responsable y el Titular es necesaria la transferencia de sus datos personales para las finalidades que a continuación se mencionan.</p>
                                    <p>Las citadas transferencias/remisiones se llevarán a cabo de la siguiente forma:</p>
                                    <p>&nbsp;</p>
                                    <table class="w-100" border="1" width="0">
                                    <tbody>
                                    <tr>
                                    <td width="179"><strong>Transferencia/Remisión</strong></td>
                                    <td width="160"><strong>Receptor</strong></td>
                                    <td width="61"><strong>País</strong></td>
                                    <td width="189"><strong>Finalidad</strong></td>
                                    </tr>
                                    <tr>
                                    <td width="179">Remisión</td>
                                    <td width="160">Despachos de Abogados.</td>
                                    <td width="61">México</td>
                                    <td width="189">Cobranza moratoria.</td>
                                    </tr>
                                    <tr>
                                    <td width="179">Remisión</td>
                                    <td width="160">Notarías Públicas.</td>
                                    <td width="61">México</td>
                                    <td width="189">Fe de Hechos.</td>
                                    </tr>
                                    <tr>
                                    <td width="179">Transferencia</td>
                                    <td width="160">Transportistas que son terceros contratistas independientes.</td>
                                    <td width="61">México</td>
                                    <td width="189">
                                    <ul>
                                    <li>Unir de manera eficiente las necesidades del Titular de transportar su mercancía a un punto por medio del Transportista a través de la Plataforma digital e informática denominada Traxporta (en lo sucesivo la “Plataforma”) que es un motor de interconexión e interacción en tiempo real entre el Titular y los Transportistas, así como a la aplicación móvil y a través de cualquiera de los medios digitales de “Traxporta”.</li>
                                    <li>Brindar soporte y asistencia al Titular.</li>
                                    <li>Verificar la identidad, antecedentes y cumplimiento de obligaciones de los requisitos legales.</li>
                                    <li>Autorizar líneas de crédito por Traxporta.</li>
                                    <li>Permitir servicios de transporte, entregas y otros servicios relacionados a través de la plataforma de Traxporta.</li>
                                    <li>Dar seguimiento de contacto, previo, durante y después de la entrega de la mercancía transportada.</li>
                                    <li>Mejorar los servicios al cliente.</li>
                                    <li>Permitir comunicación entre usuarios.</li>
                                    <li>Generar expediente del cliente para la prestación del servicio de transporte.</li>
                                    <li>Ofrecer, procesar o facilitar pagos de los servicios.</li>
                                    <li>Generar estados de cuenta y saldos y emitir Códigos de Pago, Cartas Porte, Facturas, referencia bancaria para el depósito y Comprobante Fiscal Digital.</li>
                                    <li>Abordar temas relacionados con procedimientos legales.</li>
                                    <li>Contactar al Titular para realizar cobranza.</li>
                                    <li>Realizar operaciones internas necesarias para brindar sus servicios o productos.</li>
                                    <li>Llevar investigación y desarrollo para realizar pruebas, análisis, desarrollo de productos y aprendizaje automático con el fin de mejorar su experiencia.</li>
                                    <li>Hacer llegar comunicaciones no comerciales a fin de informales sobre cambios en sus términos y condiciones, servicios o políticas o enviar otras comunicaciones que no tienen el propósito de comercializar servicios o productos.</li>
                                    <li>Toma de decisiones automatizadas en relación con el uso de servicios, incluyendo precio, asignación de transportistas, calificaciones a transportistas, desactivación de cuentas de usuarios involucrados en fraude, actividades ilícitas, o cualquier otra actividad que pudiera dañar al Transportista.</li>
                                    </ul>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td width="179">Transferencias</td>
                                    <td width="160">Aseguradoras y Fianzas</td>
                                    <td width="61">México</td>
                                    <td width="189">Contratar y pagar un seguro que cubra las posibles pérdidas o daños de la carga.</td>
                                    </tr>
                                    <tr>
                                    <td width="179">Transferencia</td>
                                    <td width="160">Sociedades controladoras, subsidiarias o afiliadas bajo el control común del Responsable, o a una sociedad matriz o a cualquier sociedad del mismo Grupo.</td>
                                    <td width="61">México</td>
                                    <td width="189">Enviar comunicaciones de marketing de servicios o productos por parte del Responsable.</td>
                                    </tr>
                                    </tbody>
                                    </table>
                                    <p>&nbsp;</p>
                                    <p>Para mayor información respecto de los destinatarios y finalidades de las citadas transferencias/remisiones, favor de comunicarse con el Responsable, en los teléfonos y correos electrónicos señalados anteriormente.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>6. Medios para ejercer los derechos de Acceso, Rectificación, Cancelación u Oposición (ARCO).</strong></p>
                                    <p>Tiene el derecho a conocer y acceder a sus datos personales que tenemos y a los detalles del tratamiento de los mismos (Acceso); así como a rectificarlos en caso de ser inexactos o estar desactualizados y/o incompletos (Rectificación); a solicitar que sean eliminados de nuestros registros o bases de datos cuando considere que los mismos no están siendo utilizados conforme a los principios, deberes y obligaciones previstas en la normativa (Cancelación); u oponerse al tratamiento de los mismos para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>
                                    <p>Se han implementado como mecanismo para el ejercicio de dichos derechos a través de la presentación de la solicitud respectiva con el Responsable, ya sea personalmente en la dirección del mismo o enviando un correo electrónico al señalado previamente. Dicha solicitud deberá contener al menos: i) Nombre completo del Titular, domicilio o cualquier otro medio para contactarlo; ii) Copia simple de identificación oficial (credencial de elector, licencia o pasaporte vigente) o bien, documento mediante el cual se acredite la representación legal del Titular; iii) Indicación expresa del derecho ARCO que desea ejercer; iv) Descripción clara y precisa de los datos personales respecto de los cuales se busca ejercer alguno de los derechos mencionados; v) Cualquier otro elemento que facilite la localización de los datos personales. En caso de ejercer el derecho de Rectificación, el Titular deberá incluir de forma adicional: las modificaciones a realizar y la documentación que sustente dicha petición.</p>
                                    <p>Considere que el Responsable tiene por Ley un plazo de veinte (20) días contados a partir de que recibió la solicitud, para comunicar al Titular la determinación adoptada a efecto de que, si resulta procedente, se haga efectiva la misma dentro de los quince (15) días siguientes a la fecha en que se comunique la respuesta.</p>
                                    <p>El Responsable podrá contactar al Titular de forma personal o escrita en su domicilio o por el medio que el Titular haya indicado en su solicitud.</p>
                                    <p>Para más información con relación al procedimiento de ejercicio de derechos, como plazos o cuándo proceden, por favor comuníquese con nosotros a través del correo electrónico, en los teléfonos y correos electrónicos antes mencionados.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>7. Mecanismos y procedimientos para que el titular, en su caso, revoque su consentimiento en cualquier momento.</strong></p>
                                    <p>Como Titular puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que considere que no en todos los casos podemos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por disposición legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá contemplar que, para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó o la conclusión de su relación con nosotros.</p>
                                    <p>Para revocar su consentimiento, deberá presentar su solicitud al Responsable a la dirección establecida en el numeral 1 del presente Aviso de Privacidad, o bien, enviarla a través de correo electrónico. Dicha solicitud deberá contener al menos: i) Nombre completo del Titular, domicilio o cualquier otro medio para contactarlo; ii) Copia simple de identificación oficial (credencial de elector, licencia o pasaporte vigente) o bien, documento mediante el cual se acredite la representación legal del Titular; iii) Indicación expresa de que revoca su consentimiento para el tratamiento de sus datos.</p>
                                    <p>Considere que el Responsable tiene por Ley un plazo de veinte (20) días contados a partir de que recibió la solicitud, para comunicar al Titular la determinación adoptada a efecto de que, si resulta procedente, se haga efectiva la misma dentro de los quince (15) días siguientes a la fecha en que se comunique la respuesta.</p>
                                    <p>El Responsable podrá contactar al Titular de forma personal o escrita en su domicilio o por el medio que el Titular haya indicado en su solicitud.</p>
                                    <p>Para más información respecto del procedimiento y los requisitos para la revocación del consentimiento, usted podrá comunicarse con el Responsable en los teléfonos y correos electrónicos antes mencionados.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>8. Opciones y medios para limitar el uso o divulgación de datos personales.</strong></p>
                                    <p>Usted puede limitar el uso y divulgación de su información personal, comunicándose con el Responsable, en los teléfonos y correos electrónicos señalados anteriormente, para determinar si podemos acceder a su petición en términos de la LFPDPPP y su Reglamento, en virtud de la relación comercial que usted tiene con el Responsable.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>9. Medidas de seguridad implementadas.</strong></p>
                                    <p>Hemos instrumentado para la protección de sus datos personales medidas de seguridad de carácter administrativo, físico y técnico con el objeto de evitar pérdidas, mal uso o alteración de su información.</p>
                                    <p>En el supuesto de que por cualquier circunstancia ocurriera una vulneración de seguridad respecto de los datos personales que tratamos, y que dicha vulneración afecte de forma significativa sus derechos patrimoniales o morales, nos pondremos en contacto con usted mediante correo electrónico o telefónicamente para informarle: i) la naturaleza del incidente; ii) los datos personales comprometidos; iii) las recomendaciones acerca de las medidas que usted puede adoptar para proteger sus intereses; iv) las acciones correctivas realizadas de forma inmediata. De igual forma usted podrá contactar en todo momento con el Responsable para obtener más información al respecto.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>10. Modificaciones al aviso de privacidad.</strong></p>
                                    <p>Se podrá modificar o actualizar el presente Aviso de Privacidad, según lo estime conveniente, de tiempo en tiempo. Podrá consultar su versión actualizada en Internet en el Sitio, o puede solicitarlo en cualquier momento al correo electrónico establecido en la sección anterior. La fecha que aparece al final de este documento le indicará cuándo se efectuó la última actualización, lo anterior derivado de modificación a disposiciones, o jurisprudenciales, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos y/o prácticas del mercado.</p>
                                    <p>&nbsp;</p>
                                    <p><strong>11. Uso de Cookies y Web Beacons.</strong></p>
                                    <p>Las cookies son archivos de texto que son descargados automáticamente y almacenados en el disco duro del equipo de cómputo del usuario al navegar en una página de Internet específica, que permiten recordar al servidor de Internet algunos datos sobre este usuario, entre ellos, sus preferencias para la visualización de las páginas en ese servidor, fecha y hora de acceso, nombre y contraseña. Por su parte, las web beacons son imágenes insertadas en una página de Internet o correo electrónico, que puede ser utilizado para monitorear el comportamiento de un visitante, como almacenar información sobre la dirección IP del usuario, duración del tiempo de interacción en dicha página y el tipo de navegador utilizado, entre otros. Le informamos que utilizamos cookies y web beacons para obtener información personal de usted, por lo que le informamos que en nuestra página de internet recabamos datos personales a través del uso de Cookies para obtener información personal de usted, como pudiera ser la siguiente:</p>
                                    <ul>
                                    <li>Su tipo de navegador y sistema operativo.</li>
                                    <li>Las páginas de Internet que visita.</li>
                                    <li>Los vínculos que sigue.</li>
                                    <li>La dirección IP.</li>
                                    <li>El sitio que visitó antes de entrar al nuestro.</li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <p><strong>12. Derecho de promover los procedimientos de protección de derechos y de verificación que sustancia el Instituto.</strong></p>
                                    <p>Si considera que su derecho ha sido lesionado por alguna conducta de nuestros empleados o de nuestras actuaciones o respuestas, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás ordenamientos aplicables, podrá interponer la queja o denuncia correspondiente ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI), para mayor información visite su página oficial de Internet: <a href="https://home.inai.org.mx/">https://home.inai.org.mx/</a></p>
                                    <p>El Titular, manifiesta estar de acuerdo con el presente Aviso de Privacidad y otorga su consentimiento para el tratamiento de sus datos personales.</p>
							</div>
						</div>
    </div>
</template>

<script>
export default {
  name: "TerminosLegales"
}
</script>

<style scoped>
    h2.txt-traxporta {
        display: inline-block;
        color: #000;
        background: #fff;
        mix-blend-mode: multiply;
        position: relative;
    }

    h2.txt-traxporta:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(to left, #b8db15, #1db2e6);
        mix-blend-mode: screen;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>