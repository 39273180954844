<template>
<div class="d-inline-block px-2" >
  <div class="d-flex justify-content-center" style="height: 40px;text-align: center;">
    <div style="width: 12px;" v-if="route.name === $route.name">
      <div style="width: 12px;height: 30px;background: linear-gradient(#b8db15, #1db2e6);"></div>
      <div style="width: 12px;height:12px;border-radius: 50%;background: white;margin-top:-6px;"></div>
    </div>
  </div>
  <router-link v-if="typeof route === 'object'" class="menu-title " :to="route">{{title}}</router-link>
  <a class="menu-title " :href="route" v-else>{{ title }}</a>
</div>
</template>

<script>
export default {
  name: "NavLink",
  props:{
    route : {
      type: [String, Object],
    },
    title: {
      type: String
    }
  }
}
</script>

<style scoped>
.menu-title{
  color:white!important;
  text-transform: uppercase;
  font-weight: bold;
}
.nav-menu{

}
</style>