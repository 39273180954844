
<template>  
  <div class="row align-items-center">
    <div v-if="post" class="col-md-12 text-center">
      <div class="abs-center">
        <div class="mt-5 text-white">
          <h1>{{post.title.rendered}}</h1>
          <p class="mt-3">{{post._embedded.author[0].name}}</p>
        </div>
      </div>
    </div>
    <div class="loading" v-else></div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "BlogHeader",
  data(){
    return {
      blog_header: [],
      loading: false,
      title_meta:'',
      description_meta:'',
      image_meta:''
    }
  },
  component:{},
  computed:{
    ...mapState(['post'])
  },
  methods:{

  }
    

}
</script>

<style scoped>
.abs-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

</style>