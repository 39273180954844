<template>
  <div class="container page-post">
    <article class="section">
      <div class="row">
        <div class="col-md-12">
          <p>
            <strong><u>Historia</u></strong>
          </p>
          <p>
            En la actualidad cada vez son más los conductores que pierden cargas
            por no tener contacto directo con los generadores de carga y a los
            generadores se les dificulta mover sus cargas porque tienen una base
            limitada de conductores, falencias como esta en el proceso actual de
            la industria hacen que se cree
            una&nbsp;<strong>plataforma</strong>&nbsp;para&nbsp;<strong
              >centralizar cargas y conductores</strong
            >, facilitando y optimizando el proceso de distribución de carga
            entregando beneficios para las dos partes.
          </p>
          <p>
            <strong>Traxporta</strong>&nbsp;es creada en marzo de 2013, basada
            en la idea de combinar la experiencia y pasión por la industria del
            transporte de carga con el fin de&nbsp;<strong
              >mejorar el servicio de logística y distribución de carga.</strong
            >&nbsp;Desde entonces se ha trabajado día a día junto con un equipo
            de más de 30 años de experiencia en diferentes áreas para construir
            lo que hasta ese entonces era tan solo una idea.
          </p>
          <p>
            Hoy en día,&nbsp;<strong>Traxporta</strong>&nbsp;es una plataforma
            que se ha desarrollado específicamente para mejorar el proceso
            actual de la industria de transporte de carga, donde generadores de
            carga gestionan sus diferentes clases de cargas a través de la
            aplicación y transportistas encuentran las cargas a través de la
            misma según su ubicación y capacidad, para así&nbsp;<strong
              >maximizar el potencial de las dos partes.</strong
            >
          </p>
          <p>
            <strong>Traxporta</strong>&nbsp;ha sido creada con la&nbsp;<strong
              >última tecnología</strong
            >&nbsp;con el fin de mejorar los procesos de la industria actual y
            estar a la vanguardia,&nbsp;<strong
              >mejorar las vidas de los transportistas incrementando sus
              ingresos,</strong
            >&nbsp;y convirtiéndose en sus propios jefes. Así mismo los
            generadores de carga también van a verse favorecidos al poderles
            ofrecer calidad de servicio y mejoramiento de precios debido a
            la<strong>&nbsp;eliminación de múltiples intermediarios.</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            <strong><u>Visión</u></strong>
          </p>
          <p>
            Ser una plataforma tan sólida que pueda alcanzar un&nbsp;<strong
              >mercado a nivel internacional,</strong
            >&nbsp;posicionándose en la mente de los consumidores
            como&nbsp;<strong
              >la primera opción cuando se trata de buscar cargas o de buscar
              transportistas</strong
            >&nbsp;para distribuir estas cargas.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong><u>Misión</u></strong>
          </p>
          <p>
            Ser una comunidad tan fuerte que podamos ser el&nbsp;<strong
              >banco de cargas número uno</strong
            >&nbsp;dentro de la industria del
            transporte,&nbsp;<strong>fidelizando</strong>&nbsp;a cada uno de
            nuestros clientes por medio de beneficios de acuerdo a sus
            necesidades.
          </p>
          <p>
            <strong>Traxporta</strong>, es una marca registrada de Traxion
            Technologies donde ofrecemos servicios de broker, logística y
            aplicaciones de software para la industria del transporte.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "Acerca",
};
</script>