<template>
  <div class="row align-items-center">
    <div class="col-md-6 text-left">
      <h1>Organiza, embarca y envía en una sola app.</h1>
      <p class="mt-3">Envía tu mercancía en el momento que lo necesites<br>con transportistas profesionales y con <b>tarifas justas</b>.</p>
      <p class="mt-3">¡<b>Publicar</b> tus cargas <b>es gratis</b>!</p>
      <p class="mt-3">Déjanos tus datos y te ayudamos a publicarlas en la app.</p>
      <div>
        <p class="download-title">Descarga la app, ¡Es gratis!</p>
        <a href="" target="_blank"><img class="img-store" src="@/assets/images/app_store.png" /></a>
        <a href="" target="_blank"><img class="img-store" src="@/assets/images/google_play.png" /></a>
      </div>
    </div>
    <div class="col-md-6">
      <div class="col-md-12" style="background-color: white;">
        <div class="container pt-5">
          <div v-if="sendSuccess" class="alert alert-success mt-5 text-center" role="alert">
            Su correo fue enviado correctamente!
          </div>
          <form class="form" @submit.prevent="sendMailContact">
              <h4 class="text-center text-letter">¡Te ayudamos a publicar tus cargas!</h4>
              <p class="text-center text-letter subtitle-form mb-3">Nuestro equipo está listo para ayudarte</p>
              <div class="form-group col-md-6 form-header">
                <label class="text-letter">Nombre</label>
                <input type="text" v-model="name" class="form-control" required>
                <small v-if="name == ''" class="text-danger float-left pl-1">Campo obligatorio</small>
              </div>
              <div class="form-group col-md-6 form-header">
                <label class="text-letter">Apellido</label>
                <input type="text" v-model="lastname" class="form-control" required>
                <small v-if="lastname == ''" class="text-danger float-left pl-1">Campo obligatorio</small>
              </div>
              <div class="form-group col-md-12">
                <label class="text-letter">Empresa</label>
                <input type="text" v-model="company" class="form-control" required>
                <small v-if="company == ''" class="text-danger float-left pl-1">Campo obligatorio</small>
              </div>
              <div class="form-group col-md-12">
                <label class="text-letter">Cargo</label>
                <input type="text" v-model="position" class="form-control" required>
                <small v-if="position == ''" class="text-danger float-left pl-1">Campo obligatorio</small>
              </div>
              <div class="form-group col-md-12">
                <label class="text-letter">Correo Electrónico</label>
                <input type="email" v-model="email" class="form-control" required>
                <small v-if="email == ''" class="text-danger float-left pl-1">Campo obligatorio</small>
              </div>
              <div class="form-group col-md-12">
                <label class="text-letter">Teléfono o celular</label>
                <input type="input" v-model="phone" class="form-control" required>
                <small v-if="phone == ''" class="text-danger float-left pl-1">Campo obligatorio</small>
              </div>
              <div class="text-center mb-5">
                <button class="btn mb-5" style="background-color: #adcc3d; color:white;" :disabled="loading">
                  Enviar Formulario
                </button>
              </div> 
          </form>
        </div>
        
      </div>
      
      
    </div>
  </div>
</template>

<script>
export default {
  name: "ShipperHeader",
  component:{

  },
  data(){
      return {
        name: null,
        email: null,
        subject : null,
        company: null,
        loading: false,
        sendSuccess: false,
        lastname: null,
        position: null,
        phone: null
      }
  },
  methods: {
    sendMailContact(){
      let error = 0
      if (!this.name) { this.name = ''; error = 1; }
      if (!this.lastname) { this.lastname = ''; error = 1; }
      if (!this.email) { this.email = ''; error = 1; }
      if (!this.company) { this.company = ''; error = 1; }
      if (!this.position) { this.position = ''; error = 1; }
      if (!this.phone) { this.phone = ''; error = 1; }

      if (!error) {
        this.loading = true
        fetch('https://api.traxporta.com/api/v2/send/mail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              name: this.name,
              lastname: this.lastname, 
              email: this.email, 
              phone: this.phone, 
              company: this.company,
              subject: "¡Registrate Ya!", 
              message: "Sección embarcadores",
              position: this.position,
              from: 'shipper'
            })
          }).then(data => {
            console.log(data)
            this.loading = false
            this.sendSuccess = true
            this.resetInput()
            window.location.href = `/shipper/thank-you`
          })
      }

      return
    },
    resetInput() {
      this.name =  null
      this.lastname =  null
      this.email =  null
      this.subject  =  null
      this.company =  null
      this.position =  null
      this.phone =  null
      
    }
  },
}
</script>

<style scoped>
.page-header h1{
  color:white;
}
.page-header p{
  color:white;
}

.text-letter{
  color: #adcc3d;
}
p{
  font-size: 1.2rem;
}
.img-store{
  width:150px;
}
.mockup{
  width: 400px;
  margin-bottom: -160px;
}

.form-header{
  display: inline-block;
}

.subtitle-form{
  color: #adcc3d !important;
}

.download-title{
  font-size: 1rem;
  margin-bottom: 0px;
  padding-left: 12px;
}
</style>