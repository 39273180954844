<template>
<div class="container">
    <div class="row mb-5 mt-5 text-center">
        <div class="col-md-6 offset-3">
            <h2 class="txt-traxporta">Gracias, en breve nos pondremos en contacto contigo</h2>
        </div>
        <div class="col-5">
            <img src="http://172.24.5.43:8082/img/e9f6b7e4-ad48-4b19-a4f4-2eb9e2c32561.15ee3307.png" alt="">
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: "ThankYou"
}
</script>

<style scoped>
    h2.txt-traxporta {
        display: inline-block;
        color: #000;
        background: #fff;
        mix-blend-mode: multiply;
        position: relative;
    }

    h2.txt-traxporta:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(to left, #b8db15, #1db2e6);
        mix-blend-mode: screen;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>