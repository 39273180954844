<template>
  <div class="container page-post">
    <article class="section">
      <div class="row">
        <div class="col-md-12">

          <p class="mt-3">
            En Traxporta, estamos comprometidos a brindar Servicios logísticos estandarizados y de Calidad sistemática,
            que permitan el aumento en la satisfacción de nuestros clientes y el cumplimiento de los requisitos de todas
            nuestras partes interesadas.
          </p>
          <p class="mt-3">
            Nos comprometemos a:
          </p>
          <ol>
            <li>Cumplir con los requisitos legales y reglamentarios aplicables en materia de transporte.</li>
            <li>Proporcionar capacitación y recursos adecuados a nuestro personal para garantizar su competencia y
              compromiso con la calidad.
            </li>
            <li>Colaborar con proveedores para garantizar la calidad en toda la cadena de transporte.</li>
            <li>Mantener un sistema de gestión de calidad que cumpla con los estándares internacionales y que sea
              continuamente mejorado.
            </li>
            <li>Evolucionar hacia una plataforma tecnológica para la gestión de nuestro servicio, clientes y proveedores
              de transporte que permita optimizar el proceso logístico y la fidelización de los socios comerciales.
            </li>
          </ol>

          <p class="mt-3">
            Esta política de calidad se revisará anualmente para garantizar su relevancia y efectividad en la mejora
            continua de nuestros servicios de transporte.
          </p>

        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "Quality",
};
</script>