<template>
  <div class="w-100 page-header" v-if="$route.name !== 'home'"
       :style="HeaderStyle">
    <div class="container px-5">
      <nav class="navbar navbar-expand-lg navbar-dark px-0">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <a class="navbar-brand" href="/">
          <img class="nav-logo" src="@/assets/images/logo_white.png" />
        </a>

        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item" v-for="(item, index) in menu" :key="'menu-'+index" >
              <nav-link :route="item.route" :title="item.title" />
            </li>
          </ul>
        </div>
      </nav>
      <slot name="content"></slot>
    </div>
  </div>

  <div class="w-100" v-else>
      
      <div class="container-fluid">
        <div class="gradient"></div>
        <div class="video-container">
            <video autoplay loop class="fillWidth visible-lg">
                <source src="@/assets/images/truck.mp4" type="video/mp4; "/>        
            </video>
        </div>
      </div>

        <div class="container page-header-home px-5">

          <nav class="navbar navbar-expand-lg navbar-dark px-0">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" href="/">
              <img class="nav-logo" src="@/assets/images/logo_white.png" />
            </a>

            <div class="collapse navbar-collapse" id="navbarToggler">
              <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                <li class="nav-item" v-for="(item, index) in menu" :key="'menu-'+index" >
                  <nav-link :route="item.route" :title="item.title" />
                </li>
              </ul>
            </div>
          </nav>

          <div class="abs-center mt-5">
             <h1 class="text-center" style="color: white;">LA NUEVA FORMA <br> DE MOVER AL MUNDO</h1>
          </div>

        </div>

          <slot name="content"></slot>
  </div>



</template>

<script>
import {mapGetters} from 'vuex';
import NavLink from "./NavLink";
export default {
  name: "Header",
  components:{
    'nav-link':NavLink
  },
  data(){
    return {
      menu : [
        {route:{name:'carrier'}, title:'Transportista'},
        {route:{name:'shipper'}, title:'Embarcador'},
        {route:'#download', title:'Descargar'},
        {route:'/#blog', title:'Blog'},
        {route:'#contact', title:'Contáctanos'},
        {route:'https://web.traxporta.com/login', title:'Iniciar Sesión'},
      ]
    }
  },
  computed:{
    ...mapGetters(['formattedPost']),
    HeaderStyle() {
      if (this.$route.name ==='blogdetail' && this.formattedPost) {
        return {
          "background": `url(${this.formattedPost.header}) no-repeat center center`,
          "-webkit-background-size": "cover",
          "-moz-background-size": "cover",
          "-o-background-size": "cover",
          "background-size": "cover",
          }
      }

      return {}
    }
  }
}
</script>

<style scoped>
.page-header{
  background: url('../assets/images/truck-bg.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.bg-header{
  background: url('../assets/images/truck-bg.png') no-repeat center center;
}
.blog-header{
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.page-header-home {
    background: url('../assets/images/textura.png') no-repeat center center;
    height: 90vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
}
.gradient::after {
    width: 100%;
    background: radial-gradient(115.98% 208.27% at 99.95% 0.27%, #7AD2F7 1%, #00B3E6 24.48%, #1DBAD4 32.81%, #93D988 68.75%, #E3ED55 100%);
    background-size: cover;
    height: 90%;
    opacity: 0.75;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.img-store{
  width:150px;
}

.nav-logo{
  width: 200px;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .nav-logo{
    width: 150px;
  }
}
.abs-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}
p{
  font-size: 1.2rem;
}

.video-container 
{
  top: 0%;
  left: 0%;
  height: 90%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index:-999;
  background-position: no-repeat center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
video.fillWidth {
  width: 100%;
  margin: 0 auto; 
}
.navigater{
  z-index: -1;
}

</style>